import { withStyles } from '@bruitt/classnames'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useRouter } from 'next/navigation'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as Sentry from '@sentry/nextjs'

import { getCookie } from '@/lib/cookies'
import { config } from '@/constants/config'
import { ROUTES } from '@/constants/routes'
import { DOCUMENT_LINK_POLITIKA, DOCUMENT_LINK_SOGLASIE } from '@/constants/constants'
import { REGION } from '@/constants/storage-keys'
import { FormField } from '@/uikit/form-field'
import { Input } from '@/uikit/input'
import { InputMask } from '@/uikit/input-mask'
import { Button } from '@/uikit/button'
import { createOrder } from '@/modules/order'
import { validationSchema } from './validation'

import styles from './simple-service-request.module.scss'

const sx = withStyles(styles)

interface SimpleServiceRequestProps {
  header?: string
  description?: string
  btnText: string
  position?: 'top' | 'center' | 'bottom'
  service?: {
    name?: string | null
    slug?: string | null
    id?: number | null
  }
  variant?: 'modal' | 'banner' | 'default'
  commentary?: string
  reachGoalName?: string
}

export const SimpleServiceRequest: FC<SimpleServiceRequestProps> = ({
  header,
  description,
  btnText,
  position = 'center',
  service,
  variant = 'default',
  commentary,
  reachGoalName,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: any) => {
    const region = getCookie(REGION)

    let regionId

    if (region) {
      regionId = JSON.parse(region).id
    }

    const recaptchaToken = (await executeRecaptcha?.('register')) ?? ''

    const params = {
      ...data,
      customerPhone: data.customerPhone.replace(/ /g, ''),
      recaptchaToken,
      regionId,
    }

    if (service?.id) {
      params.serviceId = service.id
    }

    if (commentary) {
      params.commentary = commentary
    }

    setIsLoading(true)

    let body

    try {
      const response = await createOrder(params)
      body = response.body
    } catch (e: any) {
      setIsLoading(false)
      setError(e)
      Sentry.captureException(e)

      return
    }

    try {
      if (typeof window !== 'undefined' && reachGoalName) {
        // @ts-ignore
        window?.ym(config.yandexKey, 'reachGoal', reachGoalName)
        // @ts-ignore
        window?.ym(config.yandexKey, 'reachGoal', 'lead')
      }
    } catch (e: any) {}

    setIsLoading(false)
    const orderNumber = body?.orderNumber

    const breadcrumbs = JSON.stringify({
      name: service?.name,
      slug: service?.slug,
    })

    router.push(`/${ROUTES.ORDER}?orderNumber=${orderNumber}&&breadcrumbs=${breadcrumbs}`)
  }

  return (
    <div className={sx('root', { position, variant })}>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.lds_ellipsis}>
            {' '}
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
      {error ? (
        <div className={styles.error}>
          <h5 className={styles.error_title}>Ошибка</h5>
          <div className={styles.error_text}>Произошла ошибка, повторите попытку позже</div>
          <Button variant="green" onClick={() => setError(null)}>
            Обновить страницу
          </Button>
        </div>
      ) : (
        <>
          {header && <div className={styles.header}>{header}</div>}
          <div className={styles.row}>
            {description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            <div className={styles.form}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormField
                  htmlFor="customerName"
                  label="Ваше имя*"
                  error={errors.customerName?.message ?? ''}
                >
                  <Controller
                    name="customerName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        isError={Boolean(errors.customerName?.message)}
                        placeholder="Введите имя"
                        {...field}
                      />
                    )}
                  />
                </FormField>
                <FormField
                  htmlFor="customerPhone"
                  label="Ваш телефон*"
                  error={errors.customerPhone?.message ?? ''}
                >
                  <Controller
                    name="customerPhone"
                    control={control}
                    render={({ field }) => (
                      <InputMask
                        isError={Boolean(errors.customerPhone?.message)}
                        placeholder="Введите телефон"
                        {...field}
                      />
                    )}
                  />
                </FormField>
                {variant === 'modal' && (
                  <FormField
                    htmlFor="customerEmail"
                    label="Email"
                    error={errors.customerEmail?.message ?? ''}
                  >
                    <Controller
                      name="customerEmail"
                      control={control}
                      render={({ field }) => (
                        <Input
                          isError={Boolean(errors.customerEmail?.message)}
                          type="email"
                          placeholder="name@example.com"
                          {...field}
                        />
                      )}
                    />
                  </FormField>
                )}
                <div className={styles.btn_submit}>
                  <Button type="submit" variant="green">
                    {btnText}
                  </Button>
                </div>
              </form>
            </div>
          </div>
          {position !== 'center' && (
            <div className={styles.links}>
              Я принимаю условия{' '}
              <Button
                variant="green-link"
                href={`/${ROUTES.DOCUMENTS}/${DOCUMENT_LINK_POLITIKA}`}
                target="_blank"
              >
                Политики обработки и защиты персональных данных
              </Button>
              , даю{' '}
              <Button
                variant="green-link"
                href={`/${ROUTES.DOCUMENTS}/${DOCUMENT_LINK_SOGLASIE}`}
                target="_blank"
              >
                согласие
              </Button>{' '}
              на обработку персональных данных.
            </div>
          )}
        </>
      )}
    </div>
  )
}
