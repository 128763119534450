// eslint-disable jsx-props-no-spreading
import { forwardRef } from 'react'
import { NumberFormatBase } from 'react-number-format'

import { InputWrapper, InputWrapperProps } from '@uikit/input-wrapper'

interface InputMaskProps extends InputWrapperProps, Omit<any, 'onClick' | 'size'> {}

export const InputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, ref) => {
  const { pre, post, variant, isError, disabled, onClick, size, ...rest } = props

  const _format = (val: string) => {
    if (!val) return ''

    return val
      .split('')
      .map((value: string, index: number) => {
        if (index === 0 && value === '9') {
          return '+7 9'
        }

        if (index === 0 && value !== '9' && value !== '+') {
          return '+7 '
        }

        if (index === 3) {
          return value + ' '
        }

        if (index === 6) {
          return value + ' '
        }

        if (index > 10) return

        return value
      })
      .join('')
  }

  return (
    <InputWrapper
      pre={pre}
      post={post}
      variant={variant}
      size={size}
      isError={isError}
      disabled={disabled}
      onClick={onClick}
    >
      {/*
      // @ts-ignore */}
      <NumberFormatBase format={_format} id={rest.name} {...rest} type="tel" />
    </InputWrapper>
  )
})

InputMask.displayName = 'Input'
