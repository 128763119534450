import { OrderCreateRequest, EnvelopedOrderNumberOrderCreatePublic } from '@/api'
import { orderApi } from './order.api'
import { handleApiError } from '@/lib/api'

export const createOrder = async (
  params: OrderCreateRequest,
): Promise<EnvelopedOrderNumberOrderCreatePublic> => {
  const response = await orderApi.orderCreate({ ...params }).catch(handleApiError)

  if (response === undefined) {
    throw `"response" is undefined for request "orderCreate({customerPhone: ${params.customerPhone},
      recaptchaToken: ${params.recaptchaToken},
      customerName: ${params.customerName},
      regionId: ${params.regionId},
      commentary: ${params.commentary})
    })"`
  }

  if (response.body == null) {
    throw `"response.body" is ${response.body} for request "orderCreate({customerPhone: ${params.customerPhone},
      recaptchaToken: ${params.recaptchaToken},
      customerName: ${params.customerName},
      regionId: ${params.regionId},
      commentary: ${params.commentary})
    })"`
  }

  return {
    body: response.body,
    errors: response.errors || [],
  }
}
