'use client'

import { withStyles } from '@bruitt/classnames'
import { FC, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

import { FormField } from '@/uikit/form-field'
import { Checkbox } from '@/uikit/checkbox'
import { Button } from '@/uikit/button'
import { Input } from '@/uikit/input'
import { Modal } from '@/uikit/modal'
import { Select } from '@/uikit/select-form'
import { SimpleServiceRequest } from '@/components/simple-service-request'

import styles from './calculator.module.scss'

const sx = withStyles(styles)

interface Option {
  label: string
  value: string
}

interface Field {
  label: string
  type: string
  name: string
  options?: Option[]
  isMultiple?: boolean
}

interface CalculatorProps {
  data: Field[]
  position?: 'top' | 'bottom'
  template: 1 | 2 | 'main'
  service?: {
    name?: string | null
    slug?: string | null
    id?: number
  }
  reachGoalName?: string
}

export const Calculator: FC<CalculatorProps> = ({
  data,
  position = 'bottom',
  template,
  service,
  reachGoalName,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [calculatorData, setCalculatorData] = useState()
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<any>({
    reValidateMode: 'onChange',
  })

  const { update } = useFieldArray({
    control: control,
    name: 'fields',
  })

  const onSubmit = async (value: any) => {
    let commentary

    value.fields = value.fields.filter((field: any) => {
      return Object.values(field).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0
        }

        return value ?? false
      })
    })

    if (value.fields.length) {
      commentary = value.fields
        .map((field: any) => {
          return Object.entries(field).map(([key, value]) => {
            const item = data.find(({ name }) => name === key)

            if (item?.type === 'input') {
              return [item?.label, value].join(': ')
            }

            if (Array.isArray(value)) {
              const options = value.map((value) => {
                const option = item?.options?.find((_) => _.value === value)

                return option?.label
              })

              return [item?.label, options.join(', ')].join(': ')
            }
            const option = item?.options?.find((_) => _.value === value)

            return [item?.label, option?.label].join(': ')
          })
        })
        .join(', ')
    }

    setCalculatorData(commentary)
    setIsOpen(true)
  }

  const handleChange = (value: any, index: number, name: string) => {
    const fieldValues = getValues().fields[index][name]

    if (getValues().fields[index][name]) {
      const findIndex = fieldValues.findIndex((field: string) => field === value)

      if (findIndex > -1) {
        fieldValues.splice(findIndex, 1)
        update(index, { [name]: [...fieldValues] })
      } else {
        update(index, { [name]: [...fieldValues, value] })
      }
    } else {
      update(index, { [name]: [value] })
    }
  }

  return (
    <div className={sx('root', { position, template })}>
      <div className={styles.header}>Рассчитать стоимость</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form}>
          {data.map((item, index) => (
            <div key={item.name + 'form'} className={styles.field}>
              {item.type === 'checkbox' && (
                <FormField htmlFor={`fields.${index}.${item.name}`} label={`${item.label}:`}>
                  <Controller
                    name={`fields.${index}.${item.name}`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className={styles.element_groups}>
                          {item?.options?.map((option) => (
                            <div key={item.name + option.label}>
                              {item.isMultiple ? (
                                <Checkbox
                                  key={option.value}
                                  text={option.label}
                                  type="checkbox"
                                  {...field}
                                  checked={field.value?.some(
                                    (checked: string) => checked === option['value'],
                                  )}
                                  onChange={() => handleChange(option['value'], index, item.name)}
                                />
                              ) : (
                                <Checkbox
                                  key={option.value}
                                  text={option.label}
                                  type="radio"
                                  {...field}
                                  checked={field.value === option.value}
                                  value={option.value}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  />
                </FormField>
              )}
              {item.type === 'input' && (
                <FormField htmlFor={`fields.${index}.${item.name}`} label={`${item.label}:`}>
                  <Controller
                    name={`fields.${index}.${item.name}`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        isError={Boolean(errors.customerName?.message)}
                        placeholder={`Введите ${item.label}`}
                        {...field}
                      />
                    )}
                  />
                </FormField>
              )}
              {item.type === 'select' && (
                <FormField htmlFor={`fields.${index}.${item.name}`} label={`${item.label}:`}>
                  <Controller
                    name={`fields.${index}.${item.name}`}
                    control={control}
                    render={({ field }) => (
                      <div className={styles.select}>
                        <Select placeholder="Выберите услугу" options={item.options} {...field} />
                      </div>
                    )}
                  />
                </FormField>
              )}
            </div>
          ))}
        </div>
        <div className={styles.btn_submit}>
          <Button type="submit" variant="green">
            Рассчитать стоимость
          </Button>
        </div>
      </form>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SimpleServiceRequest
          position="bottom"
          header="Отправить заявку на консультацию"
          description="<p>Предоставим полную смету на весь ремонт в день выезда замерщика.</p><br /><p>Смета включает стоимость выполнения всех работ и перечень всех необходимых материалов.</p>"
          btnText="Рассчитать стоимость"
          variant="modal"
          service={service}
          commentary={calculatorData}
          reachGoalName={reachGoalName}
        />
      </Modal>
    </div>
  )
}
