import { withStyles } from '@bruitt/classnames'
import { HTMLProps, ReactNode, forwardRef } from 'react'

import styles from './checkbox.module.scss'

export interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'className'> {
  type?: 'checkbox' | 'radio'
  text: ReactNode
  align?: 'top' | 'center'
  isError?: boolean
}

const sx = withStyles(styles)

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { type = 'checkbox', text, align = 'top', isError = false, ...inputProps } = props

  return (
    <label className={styles.root}>
      <input
        className={styles.input}
        ref={ref}
        type={type}
        checked={Boolean(inputProps.value)}
        {...inputProps}
      />
      <div className={sx('text', { align, isError })}>{text}</div>
    </label>
  )
})

Checkbox.displayName = 'Checkbox'
