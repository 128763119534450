export const TEXT_VARIABLES = {
  SERVICES: ['услуг', 'услуга', 'услуги'],
  REVIEWS: ['отзывов', 'отзыв', 'отзыва'],
} as const

export const FORM_STEPS = [
  {
    value: 1,
    name: 'one',
  },
  {
    value: 2,
    name: 'two',
  },
  {
    value: 3,
    name: 'three',
  },
  {
    value: 4,
    name: 'four',
  },
  {
    value: 5,
    name: 'five',
  },
]

export const COUNT_STATIC_PAGE = 7

export const REVALIDATE = { next: { revalidate: 3600 } }

export const DOCUMENT_LINK_POLITIKA = 'politika-obrabotki-i-zashchity-personalnykh-dannykh'
export const DOCUMENT_LINK_SOGLASIE = 'soglasie-na-obrabotku-personalnykh-dannykh'
