import { withStyles } from '@bruitt/classnames'
import { Dialog, Transition } from '@headlessui/react'
import { FC, Fragment, ReactNode, useRef } from 'react'
import { Raleway } from 'next/font/google'

import { SvgClose } from '@/uikit/svg'
import { ButtonIcon } from '@/uikit/button-icon'

import styles from './modal.module.scss'

const sx = withStyles(styles)
const raleway = Raleway({ subsets: ['cyrillic'] })

interface ModalProps {
  title?: string
  subtitle?: string
  children: ReactNode
  isOpen: boolean
  onClose?: () => void
  shouldCloseOnOverlayClick?: boolean
  className?: string
  overlayClassName?: string
}

export const Modal: FC<ModalProps> = ({
  title,
  subtitle,
  children,
  isOpen,
  onClose,
  shouldCloseOnOverlayClick = true,
  className,
  overlayClassName,
}) => {
  const ref = useRef(null)

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enterFrom={styles._isClosed}
      enterTo={styles._isOpen}
      leaveFrom={styles._isOpen}
      leaveTo={styles._isClosed}
    >
      <Dialog
        className={styles.root}
        onClose={() => shouldCloseOnOverlayClick && onClose?.()}
        initialFocus={ref}
      >
        <div className={sx('overlay', overlayClassName)} />

        <Dialog.Panel className={sx('container', raleway.className, className)}>
          <ButtonIcon className={styles.btn_close} onClick={onClose}>
            <SvgClose />
          </ButtonIcon>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div className={styles.content_wrapper}>
            <div className={styles.content}>{children}</div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  )
}
