import SelectComponent, { StylesConfig } from 'react-select'
import { forwardRef } from 'react'

interface SelectProps {
  options?: { value: string; label: string }[] | []
  placeholder?: string
  value: any
  onChange: any
  defaultValue?: string
  isMulti?: boolean
  menuPlacement?: 'top' | 'bottom' | 'auto'
  disabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
}

const colourStyles: StylesConfig = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? undefined
        : isFocused
        ? '#f0f0f1'
        : undefined,
      color: isDisabled ? '#b4b3b9' : isSelected ? '#1fa050' : '#222325',
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'rgba(31, 160, 80, 0.1)',
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#1FA050',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#1FA050',
    ':hover': {
      color: '#222325',
    },
  }),
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      options,
      placeholder,
      value,
      onChange,
      defaultValue,
      isMulti = false,
      menuPlacement = 'auto',
      disabled = false,
      isLoading = false,
      isClearable = false,
    },
    ref,
  ) => {
    return (
      <SelectComponent
        instanceId="select"
        value={isMulti ? value : options?.find((c) => c.value === value)}
        onChange={(val) => {
          if (isMulti) {
            onChange(val)
            return
          }

          onChange(val?.value ? val?.value : undefined)
        }}
        options={options}
        className="select"
        classNamePrefix="select"
        placeholder={placeholder}
        styles={colourStyles}
        defaultValue={defaultValue}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        noOptionsMessage={() => 'Список пуст'}
        isDisabled={disabled}
        isClearable={isClearable}
        isLoading={isLoading}
      />
    )
  },
)

Select.displayName = 'Select'
