import * as yup from 'yup'

export const stringShema = yup.string().required('Обязательное поле')

export const numberSchema = yup.number().required('Обязательное поле')

export const imageShema = yup.lazy((value) => {
  switch (typeof value) {
    case 'object':
      return yup.mixed().required('Обязательное поле')
    case 'string':
      return yup.string()
    default:
      return yup.string().required('Обязательное поле')
  }
})

export const useLocalMetaField = yup.string().when('useLocalMeta', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalSubtitleField = yup.string().when('useLocalSubtitle', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalDescriptionField = yup.string().when('useLocalDescription', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalWorkDescriptionField = yup.string().when('useLocalWorkDescription', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalMetaFieldMain = yup.string().when('useLocalMetaMain', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalSubtitleFieldMain = yup.string().when('useLocalSubtitleMain', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalDescriptionFieldMain = yup.string().when('useLocalDescriptionMain', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})

export const useLocalWorkDescriptionFieldMain = yup.string().when('useLocalWorkDescriptionMain', {
  is: true,
  then: (schema) => schema.required('Обязательное поле'),
})
